import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ text, icon, classes, link }) => {
    return (
        <Link
            className={`btn-property ${classes} cursor`}
            to={link}
            target='_blank'
            aria-label={text}
        >
            {icon}
            {text}
        </Link>
    )
}

export default Button
