import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { API_URL } from './config'
import "./index.css";
import "./css/fontCss.css";
import "./css/widthCss.css";
import "./css/appCss.css";
import "./css/componentsCss.css";
import "./css/buttons.css";
import "./css/colors.css";
import Home from './pages/Home';
import ContestInfo from './pages/ContestInfo';
import NotFound from './pages/NotFound';
import Test from './pages/Test';
import ContestRegistrations from './pages/ContestRegistrations';
import { setDefaultCategory, setFetchingUser, setJwtToken, setUser } from './reducers/user'
import axios from 'axios'
import Contests from './pages/Contest';
import Mentorship from './pages/Mentorship';
import Community from './pages/Community';
import Practice from './pages/Practice';
import Result from './pages/Result';
import VerifyToken from './pages/VerifyToken';
import CookieConsent, { Cookies } from "react-cookie-consent";
import Loader from './components/Loader';
import About from './pages/About';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Standings from './pages/Standings';
import Refer from './pages/Refer';
import Results from './pages/Results';
import Solutions from './pages/Solutions';
import ContestLists from './pages/ContestLists';
import PracticeQuestion from './pages/PracticeQuestion';
import JsonAsset from './pages/JsonAsset';
import ProfileUpdated from './pages/ProfileUpdated';
import Contests_App from './app_pages/Contest_App';
import ContestInfo_App from './app_pages/ContestInfo';
import ContestRegistrantions_App from './app_pages/ContestRegistrations';
import Test_App from './app_pages/Test';
import Standings_App from './app_pages/Standings';
import Results_App from './app_pages/Results';
import Solutions_App from './app_pages/Solutions';
import ContestLists_App from './app_pages/ContestLists';
import PracticeQuestion_App from './app_pages/PracticeQuestion';
import VirtualTest from './pages/VirtualTest';
import VirtualTest_App from './app_pages/VirtualTest_App';
import Privacy_App from './app_pages/Privacy';
import Terms_App from './app_pages/Terms';
import CommunityPage from './pages/CommunityPage';
import Refund from './pages/Refund';
import Pricing from './pages/Pricing';
import CommunityV2 from './pages/CommunityV2';

const router = createBrowserRouter([
  {
    path: "/",
    element: <CommunityV2 />,
  },
  // {
  //   path: '/unbindhome',
  //   element: <Home />
  // },
  // {
  //   path: '/contest',
  //   element: <Contests />
  // },
  // {
  //   path: "/contest/category/:type",
  //   element: <Contests />,
  // },
  // {
  //   path: "/contest/contest-detail/:id",
  //   element: <ContestInfo />,
  // },
  // {
  //   path: "/contest/contestRegistrants/:id",
  //   element: <ContestRegistrations />,
  // },
  // {
  //   path: "/contest/virtual/start/:id/:virtualId",
  //   element: <VirtualTest />,
  // },
  // {
  //   path: "/contest/start/:id/:registerId",
  //   element: <Test />,
  // },
  // {
  //   path: "/contest/standings/:id",
  //   element: <Standings />
  // },
  // {
  //   path: "/contest/result/:userId/:id",
  //   element: <Results />
  // },
  // {
  //   path: "/contest/result/:userId/:id/:virtualId",
  //   element: <Results />
  // },
  // {
  //   path: '/contest/solutions/:userId/:id',
  //   element: <Solutions />
  // },
  // {
  //   path: '/contest/solutions/:userId/:id/:virtualId',
  //   element: <Solutions />
  // },
  {
    path: "/terms&conditions",
    element: <Terms />
  },
  {
    path: "/privacy",
    element: <Privacy />
  },
  {
    path: "/refund",
    element: <Refund />
  },
  // {
  //   path: "/pricing",
  //   element: <Pricing />
  // },
  // {
  //   path: '/app/contest',
  //   element: <Contests_App />
  // },
  // {
  //   path: "/app/contest/category/:type",
  //   element: <Contests_App />,
  // },
  // {
  //   path: "/app/contest/contest-detail/:id",
  //   element: <ContestInfo_App />,
  // },
  // {
  //   path: "/app/contest/contestRegistrants/:id",
  //   element: <ContestRegistrantions_App />,
  // },
  // {
  //   path: "/app/contest/virtual/start/:id/:virtualId",
  //   element: <VirtualTest_App />,
  // },
  // {
  //   path: "/app/contest/start/:id/:registerId",
  //   element: <Test_App />,
  // },
  // {
  //   path: "/app/contest/standings/:id",
  //   element: <Standings_App />
  // },
  // {
  //   path: "/app/contest/result/:userId/:id",
  //   element: <Results_App />
  // },
  // {
  //   path: "/app/contest/result/:userId/:id/:virtualId",
  //   element: <Results_App />
  // },
  // {
  //   path: '/app/contest/solutions/:userId/:id',
  //   element: <Solutions_App />
  // },
  // {
  //   path: '/app/contest/solutions/:userId/:id/:virtualId',
  //   element: <Solutions_App />
  // },
  // {
  //   path: "/app/terms&conditions",
  //   element: <Terms_App />
  // },
  // {
  //   path: "/app/privacy",
  //   element: <Privacy_App />
  // },
  // {
  //   path: "/mentorship",
  //   element: <Mentorship />,
  // },
  // // {
  // //   path: "/community",
  // //   element: <Community />,
  // // },
  // {
  //   path: "/practice",
  //   element: <ContestLists />,
  // },
  // {
  //   path: "/practice/:subjectId",
  //   element: <ContestLists />,
  // },
  // {
  //   path: "/practice/:subjectId/:topicId",
  //   element: <ContestLists />,
  // },
  // {
  //   path: "/practice/:subjectId/:topicId/:questionId",
  //   element: <PracticeQuestion />
  // },
  // {
  //   path: "/app/practice",
  //   element: <ContestLists_App />,
  // },
  // {
  //   path: "/app/practice/:subjectId",
  //   element: <ContestLists_App />,
  // },
  // {
  //   path: "/app/practice/:subjectId/:topicId",
  //   element: <ContestLists_App />,
  // },
  // {
  //   path: "/app/practice/:subjectId/:topicId/:questionId",
  //   element: <PracticeQuestion_App />
  // },
  // {
  //   path: "/profile/:id",
  //   element: <ProfileUpdated />,
  // },
  // {
  //   path: "/refer",
  //   element: <ProfileUpdated />
  // },
  // {
  //   path: "/bookmark",
  //   element: <ProfileUpdated />
  // },
  // {
  //   path: "/talk-sessions",
  //   element: <ProfileUpdated />
  // },
  // {
  //   path: "/token/:token",
  //   element: <VerifyToken />,
  // },
  {
    path: "*",
    element: <NotFound />,
  },
  // {
  //   path: "/about",
  //   element: <About />
  // },
  {
    path: '/.well-known/assetlinks.json',
    element: <JsonAsset />
  }
]);


export default function App() {

  const [cookies, setCookie, removeCookie] = useCookies(['token', 'referrer']);
  const jwtToken = useSelector((state) => state.user.jwtToken)
  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener('WEB_VIEW_TOKEN_LISTNER', () => {
      if (window.localStorage.getItem("WEB_VIEW_TOKEN"))
        dispatch(setJwtToken(window.localStorage.getItem("WEB_VIEW_TOKEN")));
    })
  }, [])

  useEffect(() => {
    if (cookies && cookies.token && jwtToken === null) {
      dispatch(setJwtToken(cookies["token"]));
    }
  }, [cookies])

  useEffect(() => {
    console.log("cookies==================", cookies)
    if (jwtToken) {
      fetchCurrentUser();
      setCookie('token', jwtToken, {
        path: "/",
        maxAge: 31536000
      });

      if (cookies['referrer']) {
        console.log(cookies.referrer)
        setReferrer(jwtToken, cookies.referrer);
      }

    }
  }, [jwtToken])

  const fetchCurrentUser = async () => {
    try {
      dispatch(setFetchingUser(true))
      const res = await axios.get(API_URL + "/currentUser", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        }
      });
      console.log("current user==========", res.data)
      dispatch(setUser(res.data))
      if (res.data.chosenCategory) {
        dispatch(setDefaultCategory(res.data.chosenCategory))
      }
      dispatch(setFetchingUser(false))
    } catch (e) {
      dispatch(setFetchingUser(false))
      console.log(e)
    }
  }

  const setReferrer = async (jwtToken, referrer) => {
    try {
      const res = await axios.post(API_URL + "/setReferral?referralId=" + referrer, false, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        }
      });
      console.log("set referrer==========", res.data)
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <RouterProvider router={router} />
  )
}


