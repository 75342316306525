import React from "react";
import GradientBlob3 from "./GradientBlob3";
import PlannerImg from "../../assets/planner.webp";
import b1 from "../../assets/progress_tracker.webp";
import b2 from "../../assets/community.webp";
import b3 from "../../assets/mistake_book.webp";
import b4 from "../../assets/custom_test.webp";
import { Link } from "react-router-dom";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram, AiFillLinkedin, AiFillYoutube } from "react-icons/ai";
import GradientBlob4 from "./GradientBlob4";

const BriefInfoV2 = () => {
  return (
    <div
      className="d-flex flex-column align-items-center gap-1 py-5 position-relative"
      style={{ overflow: "hidden" }}
    >
      <div className="blob3">
        <GradientBlob3 />
      </div>
      <div className="blob4">
        <GradientBlob3 />
      </div>
      <h2 className="col-md-10 col-11 mx-auto text-center community-font-1 community-font-gradient pt-3 fw-7">
        Personalized Study Planner
      </h2>

      <div className="col-md-10 col-11">
        <div className="col-12">
          <h3
            className="community-font-2 text-center"
            style={{ color: "var(--gray-400)", lineHeight: 1.4 }}
          >
            Customize your study schedule, set achievable goals,
            <br className="d-none d-md-block" /> and keep track of subjects
            efficiently.
          </h3>
        </div>
      </div>

      <div className="col-md-10 col-11 my-5 text-center relative">
        <img
          src={PlannerImg}
          style={{ maxWidth: "1200px", width: "80vw" }}
          alt="unbind-planner"
        />
        {/* <GradientBlob4
          width="50%"
          top="5%"
          right="2%"
         /> */}
      </div>

      <ImageGallery
        title="Progress Tracker"
        subtitle="Real-time progress analysis with insights into strong and weak areas."
        img={b1}
        // scale={1.25}
        features={[
          "Track NCERT reading completion",
          "Monitor PYQs practice status",
          "Mark lecture attendance for each topic",
          "Highlight topics with doubts",
          "Visual progress bar for syllabus coverage",
          "Track Revision Progress",
        ]}
      />

      <ImageGallery
        title="AI-Powered Custom Tests"
        subtitle="Create tailored tests based on your strengths and improvement areas, generated by AI."
        img={b4}
        reverse
        features={[
          "Personalized Test Generation",
          "Adaptive Question Selection",
          "Subject-Wise Analysis",
          "NEET-Aligned Practice",
          "Performance Insights by Topic",
        ]}
      />

      <ImageGallery
        title="Supportive Community"
        subtitle="Connect with fellow NEET aspirants, share experiences, tips, and motivation."
        img={b2}
        features={[
          "Real-Time Progress Updates",
          "Detailed Performance Insights",
          "Subject-Wise Analysis",
          "Goal Tracking",
          "Historical Data View",
        ]}
      />

      <ImageGallery
        title="Mistake Book"
        subtitle="The Mistake Book is your dedicated tool for tracking errors and understanding areas that need improvement."
        img={b3}
        reverse
        features={["Detailed Error Logging", "Subject & Topic Organization"]}
      />

      <div className="d-flex mt-5 pt-md-5 align-items-center gap-2 col-md-10 col-11 justify-content-center text-center community-fontSize-1">
        <h2 className="text-center community-font-1 community-font-gradient pt-3 fw-7">
          Let's Crack NEET
        </h2>
        🔥
      </div>
      <div className="d-flex mb-4 gap-3 align-items-center fs-3">
        <Link
          to={"https://www.facebook.com/share/BumVYxXa2JfL56Cu/"}
          target="_blank"
          className="linkFoot"
        >
          <BsFacebook style={{ cursor: "pointer" }} />
        </Link>
        <Link
          to={"https://www.instagram.com/unprep.ai/"}
          target="_blank"
          className="linkFoot"
        >
          <AiFillInstagram className="fs-2" style={{ cursor: "pointer" }} />
        </Link>
        <Link
          to={"https://www.linkedin.com/company/unprep/"}
          target="_blank"
          className="linkFoot"
        >
          <AiFillLinkedin className="fs-2" style={{ cursor: "pointer" }} />
        </Link>
        <Link
          to={"https://youtube.com/@unprep-e6p?si=8XkxIt8_En4ZKZtm"}
          target="_blank"
          className="linkFoot"
        >
          <AiFillYoutube className="fs-2" style={{ cursor: "pointer" }} />
        </Link>
      </div>
    </div>
  );
};

const ImageGallery = ({ title, subtitle, features, img, reverse = false, scale }) => {
  return (
    <div
      className={`col-md-10 col-11 mx-auto my-5 d-flex flex-column justify-content-between mt-5 pt-lg-4 pt-lg-2 pt-0 ${
        reverse ? "flex-md-row-reverse" : "flex-md-row"
      }`}
    >
      <div className="col-md-6 col-12 d-flex flex-column align-items-center align-items-md-start">
        <h2 className="community-fontSize-2 text-md-start text-center fw-7">
          {title}
        </h2>
        <h3
          className="community-font-4 col-11 col-md-12 mb-md-4 text-center text-md-start"
          style={{ color: "#969696" }}
        >
          {subtitle}
        </h3>

        {features && (
          <ul
            className="d-none d-md-block community-fontSize-5"
            style={{ color: "#969696DF" }}
          >
            {features.map((feature, index) => (
              <li key={index} className="mb-2">
                {feature}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div
        className={`col-md-6 col-12 mt-4 mt-sm-5 mt-md-0 d-flex flex-column align-items-center gap-3 h-fit ${
          reverse ? "align-items-md-start" : "align-items-md-end"
        }`}
        style={{height: 'fit-content'}}
      >
        <img
          src={img}
          style={{ maxWidth: "500px", width: "70%", transform: `scale(${scale})`, height: "auto" }}
          alt="unbind-contests"
        />
      </div>
    </div>
  );
};

export default BriefInfoV2;
